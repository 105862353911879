import React from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";

import "normalize.css";
import "typeface-open-sans";
import "typeface-roboto";

import { theme } from "../theme";

import Header from "./header";

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Open-Sans, Helvetica, sans-serif;
    font-size: 16px;
    color: ${({ theme }) => theme.fontColor};
  }
`;

const Layout: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Header />
      {children}
    </ThemeProvider>
  );
};

export default Layout;
