import React from "react";
import styled from "styled-components";
// import { Link } from "gatsby";

import "typeface-syncopate";

const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Title = styled.h1`
  font-size: 9vw;
  letter-spacing: -0.4rem;
  text-align: center;
  margin: 0;
  font-family: "Syncopate", Helvetica, sans-serif;
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;

  /* @media (max-width: 736px) {
    font-size: 1.5rem;
  } */

  span {
    display: block;
    font-size: 2.5vw;
    letter-spacing: 1rem;
  }
`;

// const LinksContainer = styled.ul`
//   display: flex;
//   list-style: none;
//   margin: 0;
// `;

// const LinkItem = styled.li`
//   padding: 0.5rem 1rem;

//   a {
//     color: ${({ theme }) => theme.colors.white};
//     text-decoration: none;
//     font-size: 1.2rem;
//     font-weight: 300;

//     :hover {
//       color: ${({ theme }) => theme.colors.flax};
//     }
//   }
// `;

// const links = [
//   {
//     text: "Home",
//     to: "/",
//   },
//   {
//     text: "About",
//     to: "/about",
//   },
//   {
//     text: "Mares",
//     to: "/mares",
//   },
//   {
//     text: "Foals",
//     to: "/foals",
//   },
// ];

const Header: React.FC = () => {
  return (
    <Container>
      <Title>
        Soaring Eagle
        <span>Equestrian Center</span>
      </Title>
      {/* <nav aria-label="Main Menu">
        <LinksContainer>
          {links.map((link) => (
            <LinkItem key={link.to}>
              <Link to={link.to}>{link.text}</Link>
            </LinkItem>
          ))}
        </LinksContainer>
      </nav> */}
    </Container>
  );
};

export default Header;
