import { DefaultTheme } from "styled-components";

export const colors = {
  flax: "rgba(226, 202, 120, 1)",
  oldMauve: "rgba(96, 49, 64, 1)",
  armyGreen: "rgba(65, 82, 31, 1)",
  pewterBlue: "rgba(139, 170, 173, 1)",
  languidLavender: "rgba(196, 183, 203, 1)",
  smokeyBlack: "rgba(13, 7, 9, 1)",
  white: "rgba(256, 256, 256, 1)",

  header: "rgba(13, 7, 9, 0.6)",
};

export const theme: DefaultTheme = {
  fontColor: colors.white,

  colors,
};
