import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import Layout from "../components/layout";

const Video = styled.video`
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translate(-50%, -50%);
`;

const OverlayContainer = styled.div`
  max-height: 100vh;
`;

const Overlay = styled.div`
  position: fixed;
  top: -100px;
  bottom: -100px;
  left: -100px;
  right: -100px;
  z-index: -1;
  background: ${({ theme }) => theme.colors.header};
`;

const Home: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.85;
    }
  }, [videoRef.current]);

  return (
    <Layout>
      <main>
        <OverlayContainer>
          <Video
            ref={videoRef}
            autoPlay
            loop
            muted
            playsInline
            src="https://soaringeagle.azureedge.net/assets/soaring-eagle-1080.mp4"
          />
          <Overlay />
        </OverlayContainer>
      </main>
    </Layout>
  );
};

export default Home;
